import React from 'react'
import Slider from "react-slick";
import d2 from "../../assets/images/widows/w3.jpeg"
import d4 from "../../assets/images/children/c1.jpeg"
import d5 from "../../assets/images/p-b-1.jpg"
import './header.css'

const Header = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 8000,
        cssEase: "linear"
      };
  return (
    <div id='home' className='header'>
        <div>
        <Slider {...settings}>
          <div className='slider-container'>
            <div className='slider-image'>
            <div className='background-opacity'></div>
                <img src={d4} alt="slider-pic" />
            </div>
            <div className="header-title">
                <h1>Charity for Children</h1>
                <p> Caring Hearts: Empowering Children's Futures. </p>
            </div>
          </div>
          <div className='slider-container'>
            <div className='slider-image'>
            <div className='background-opacity'></div>
                <img src={d2} alt="slider-pic" style={{transform: 'scaleX(-1)'}} />
            </div>
            <div className="header-title">
                <h1>Charity for widow</h1>
                <p> Hopeful Widows: Rebuilding Lives, Restoring Dignity.</p>
            </div>
          </div>
          <div className='slider-container'>
            <div className='slider-image'>
            <div className='background-opacity'></div>
                <img style={{transform: 'scaleX(-1)'}} src={d5} alt="slider-pic" />
            </div>
            <div className="header-title">
                <h1>Charity for Prison Outreach</h1>
                <p>Second Chances: Transforming Lives Through Prison Outreach.</p>
            </div>
          </div>
         
        </Slider>
      </div>
    </div> 
  )
}

export default Header