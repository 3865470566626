import React, { useEffect } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import image from '../../assets/images/gal.jpg'

import { BsFillArrowRightCircleFill } from "react-icons/bs"

import pic5 from '../../assets/images/specialNeeds/sn1.jpeg'
import pic1 from '../../assets/images/specialNeeds/sn2.jpeg'
import pic2 from '../../assets/images/specialNeeds/sn3.jpeg'
import pic3 from '../../assets/images/specialNeeds/sn4.jpeg'
import pic4 from '../../assets/images/d2.jpeg'

import w1 from "../../assets/images/widows/w1.jpeg"
import w2 from "../../assets/images/widows/w2.jpeg"
import w3 from "../../assets/images/widows/w3.jpeg"
import w4 from "../../assets/images/widows/w4.jpeg"
import w5 from "../../assets/images/widows/w5.jpeg"

import p1 from "../../assets/images/prison/p1.jpeg"
import p2 from "../../assets/images/prison/p2.jpeg"
import p3 from "../../assets/images/prison/p3.jpeg"
import p4 from "../../assets/images/prison/p4.jpeg"

import c1 from "../../assets/images/children/c1.jpeg"
import c2 from "../../assets/images/children/c2.jpeg"
import c3 from "../../assets/images/children/c3.jpeg"
import c4 from "../../assets/images/children/c4.jpeg"
import c5 from "../../assets/images/children/c5.jpeg"
import c6 from "../../assets/images/children/c6.jpeg"
import c7 from "../../assets/images/children/c7.jpeg"
import AOS from 'aos';
import 'aos/dist/aos.css';

import './projectpage.css'

const ProjectPage = () => {

    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div className='projectpage'>
        <Navbar />
        <div className="projectpage-container">
        <div id='gallery' className="contact-header">
              <div className="contact-header-image">
              <div className='contact-opacity'></div>
                <img src={image} alt="contact"  style={{ transform: "scaleX(-1)", objectPosition: "0px 100%"}}/>
              </div>
              <div className="contact-header-text"  data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                <h1>Gallery</h1>
              </div>
              
        </div>

        <div className="gallery-container">
            <div className="gallery-title" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                <h3>Past Events</h3>
                <h1>Relive the Moments: Where Memories Meet Impact</h1>
            </div>
            <div className='gallery-content-container'>
              <h1 data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">Event Title: Second Chance (Prison Visitation) </h1>
          
                <div className="gallery-image-container">
                  
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={p1} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={p2} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={p3} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={p4} alt=" gallery " className='page-gallery-image' />
                    </div>   
                </div>
            </div>
            <div className='gallery-content-container'>
              <h1 data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">Event Title: Children Outreach </h1>
          
                <div className="gallery-image-container">
                  
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={c1} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={c2} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={c3} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={c4} alt=" gallery " className='page-gallery-image' />
                    </div>  
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={c5} alt=" gallery " className='page-gallery-image' />
                    </div> 
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={c6} alt=" gallery " className='page-gallery-image' />
                    </div> 
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={c7} alt=" gallery " className='page-gallery-image' />
                    </div>  
                </div>
            </div>
              <div className='gallery-content-container'>
              <h1 data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">Event Title: Special Needs Outreach </h1>
          
                <div className="gallery-image-container">
                  
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={pic1} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={pic2} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={pic3} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={pic5} alt=" gallery " className='page-gallery-image' />
                    </div>
                  
                </div>
            </div>
            <div className='gallery-content-container'>
              <h1 data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">Event Title: Widows Outreach</h1>
          
                <div className="gallery-image-container">
                  
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={w1} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={w2} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={w3} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={w4} alt=" gallery " className='page-gallery-image' />
                    </div>
                    <div className="gallery-images " data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <img src={w5} alt=" gallery " className='page-gallery-image' />
                    </div>
                  
                </div>
            </div>
        </div>
        </div>
        <Footer />
    </div>
  )
}

export default ProjectPage