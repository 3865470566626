import React, { useEffect, useState } from 'react';
import './stats.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import CountUp from 'react-countup';

const Stats = () => {
  const [inViewport, setInViewport] = useState(false);

  const handleScroll = () => {
    // Calculate the position of the element relative to the viewport
    const element = document.querySelector('.statistics');
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    // Check if the element is in the viewport
    if (rect.top >= 0 && rect.bottom <= windowHeight) {
      setInViewport(true);
    }
  };

  useEffect(() => {
    AOS.init();
    // Attach a scroll event listener
    window.addEventListener('scroll', handleScroll);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='statistics' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
      <div className="statistics-container">
        <div className="statistics-content">
          <h1>
            {inViewport && (
              <CountUp start={0} end={10} duration={7} />
            )}
          </h1>
          <p>Projects Done</p>
        </div>
        <div className="statistics-content">
          <h1>
            {inViewport && (
              <CountUp start={0} end={400} duration={5} />
            )}
          </h1>
          <p>Happy People</p>
        </div>
        <div className="statistics-content">
          <h1>
            {inViewport && (
              <CountUp start={0} end={10} duration={7} />
            )}
            +
          </h1>
          <p>Volunteers</p>
        </div>
      </div>
    </div>
  );
};

export default Stats;
