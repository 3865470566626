import React, { useEffect } from 'react'
import './joinus.css'
import banner from '../../assets/images/vol-banner.jpg'
import { BsFillArrowRightCircleFill } from "react-icons/bs"
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';

const JoinUs = () => {

  const navigate = useNavigate()
       
  const reload = () => {
      navigate('/contact');
    };

    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div className='joinus'>
        <div className="joinus-container">
            <div className="joinus-image">
                <div className='joinus-opacity'></div>
                <img src={banner} alt="banner" />
            </div>
            <div className="joinus-content" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                <h1>Join Our Cause: Volunteer Today to Shape a Better Tomorrow</h1>
                <div className="joinus-button" onClick={reload}>
                    <BsFillArrowRightCircleFill className='home-about-icon2' />
                    <p>Learn More</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default JoinUs