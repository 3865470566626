import React from 'react'
import './map.css'
const Map = () => {
  return (
    <div className='map'>
      <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.406870912568!2d3.2812890999999995!3d6.470034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b88c64ce380a5%3A0x4533faf17fdb2cab!2s51%20Rd%2C%20Festac%20Town%20102102%2C%20Satellite%20Town%2C%20Lagos!5e0!3m2!1sen!2sng!4v1696901127218!5m2!1sen!2sng"
      width="100%" 
      height="420" 
      style={{border:'none', outline:'none'}}
      >
      </iframe>  
     </div>
  )
}

export default Map