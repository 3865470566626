import React, {useEffect, useState} from 'react'
import Slider from "react-slick";
import './testimonial.css'
import user1 from '../../assets/images/profile2.png'

import AOS from 'aos';
import 'aos/dist/aos.css';

const Testimonial = () => {
  const [slidesToShow, setSlidesToShow] = useState(2);

    useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth >= 500) {
          setSlidesToShow(2); // Large screen
        }  else {
          setSlidesToShow(1); // Small screen
        }
      };
  
      // Initial setup
      handleResize();
  
      // Listen for window resize events
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []); 


  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
     slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear"

  };

  useEffect(()=>{

  },[])

  return (
    <div className='testimonials'>
      <div className="testimonial-container">
      <div className="testimonials-title-container"  >
         <div className="home-about-title" >
            <div className="about-vertical"></div>
              <h1 style={{color: '#75C2F6'}}>Testimonials</h1>
            </div>
          <h2>Discover What Others Are Raving About.</h2>
      </div>
      <div className="testimonial-carousel" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
      <Slider {...settings}>
        <div className="div-slider-space">
          <div className='testimonial-card'>
            <p>Volunteering here was a game-changer! Proud to be part of a team that truly makes things happen.</p>
            <div className="testimonial-card-image-container">
              <div className="testimonial-card-image">
                <img src={user1} alt="" />
              </div>
              <div className="testimonial-card-author">
                <h1>Chioma Peter</h1>
                <p>Volunteer</p>
              </div>
            </div>
          </div>
          </div>
          <div className="div-slider-space">
          <div className='testimonial-card'>
            <p>Joining this mission has proven to be an exceptional decision. Empowering, inspiring, and incredibly rewarding.</p>
            <div className="testimonial-card-image-container">
              <div className="testimonial-card-image">
                <img src={user1} alt="" />
              </div>
              <div className="testimonial-card-author">
                <h1>Louisa Okonkwo</h1>
                <p>Volunteer</p>
              </div>
            </div>
          </div>
          </div>
          <div className="div-slider-space">
          <div className='testimonial-card'>
            <p>Volunteering transformed my perspective. Heartwarming experiences and lasting friendships.</p>
            <div className="testimonial-card-image-container">
              <div className="testimonial-card-image">
                <img src={user1} alt="" />
              </div>
              <div className="testimonial-card-author">
                <h1>Mitchell Onujeme</h1>
                <p>Volunteer</p>
              </div>
            </div>
          </div>
          </div>
         
      </Slider>
      </div>
      </div>
    </div>
  )
}

export default Testimonial