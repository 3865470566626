import React, { useEffect } from 'react'
import { BsFillArrowRightCircleFill } from "react-icons/bs"
import "./homeabout.css"
import image from '../../assets/images/specialNeeds/sn4.jpeg'
import image2 from '../../assets/images/specialNeeds/sn2.jpeg'

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link, useNavigate } from 'react-router-dom'


const HomeAbout = () => {

    // const navigateToAbout = () => {
    //     // Use the URL of the page you want to navigate to
    //     const newUrl = '/about';
        
    //     if (window.location.pathname === newUrl) {
    //       // If the user is already on the target page, perform a full page reload
    //       window.location.reload();
    //     } else {
    //       // Navigate to the new page
    //       window.location.href = newUrl;
    //     }
    //   }; 

        // This forces a full page reload and starts from the top
        
        const navigate = useNavigate()
       
        const reload = () => {
            navigate('/about');
          };

    useEffect(()=>{
        AOS.init();
       
    }, [])
  return (
    <div className="home-about">
        <div className="home-about-container">
            <div className="home-about-image">
                <div className="home-about-image1" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                    <img src={image2} alt="about" />
                </div>
                <div className="home-about-image2" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000"></div>
                <div className="home-about-image3" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                <img src={image} alt="about" />
                </div>
                
            </div>
            <div className="home-about-content">
                <div className="home-about-title" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                    <div className="about-vertical"></div>
                    <h1>About FAFAEDA</h1>
                </div>
                <div className="home-about-description" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                    <h1>Making the World a Better Place Together</h1>
                    <p>A passionate team dedicated to creating positive change and fostering a better future for all. Committed to empowering lives, fostering education, and spreading compassion in every community we touch.</p>
                </div>
                <div className="m-v">
                    <div className="home-about-m-v" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <div className="home-about-header">
                            <BsFillArrowRightCircleFill className='home-about-icon'/>
                            <h1>Our Mission</h1>
                        </div>
                        <p>To promote an emotional, mental and economic shelter for oppressed widows and less privilege children and youth, reassuring them of a brighter future despite all emotional and domestic abuse they have experienced.</p>
                    </div>
                    <div className="home-about-m-v" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                        <div className="home-about-header">
                            <BsFillArrowRightCircleFill className='home-about-icon' />
                            <h1>Our Vision</h1>
                        </div>
                        <p>To provide succor and comfort to marginalized widows and less privileged across Nigeria.</p>
                    </div>
                </div>
                <div onClick={reload} className="home-about-button" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                    
                     <BsFillArrowRightCircleFill className='home-about-icon2'/>
                    <p>Learn More</p>
                    
                </div>
               
            </div>
        </div>
    </div>
  )
}

export default HomeAbout