import React, {useEffect, useState} from 'react'
import heart from '../../assets/images/heart.png'
import { AiFillHeart } from "react-icons/ai";
import './cta.css'

import AOS from 'aos';
import 'aos/dist/aos.css';

import DonateButton from '../../constants/btn/DonateButton';

import bank from '../../assets/images/bank.png'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const CTA = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(()=>{
    AOS.init();
  },[])

  return (
    <div className='cta'>
      <div className="cta-container">
        <div className="cta-image" data-aos="fade-right"  data-aos-easing="linear" data-aos-duration="1000">
          <img src={heart} alt="heart" />
        </div>
        <div className="cta-text" data-aos="zoom-in"  data-aos-easing="linear" data-aos-duration="1000">
          <h1>Create a Positive Impact in the Lives of Others</h1>
        </div>
        <div data-aos="fade-left"  data-aos-easing="linear" data-aos-duration="1000">
          <DonateButton onClick={handleShow} />
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='donation-title'>Donations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="donation-acct">
            <img src={bank} alt="transfer" />
              <p style={{textAlign: 'center'}}>Account Name: First Aid Foundation Against Emotion</p>
              <p style={{textAlign: 'center'}}> Sterling Bank: 0083528298</p>
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button  variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CTA