import React, { useState, useEffect } from 'react'
import axios from 'axios';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './contactpage.css'

import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import image from '../../assets/images/con.jpg'
import image1 from '../../assets/images/chat.png'
import image2 from '../../assets/images/mail.png'
import image3 from '../../assets/images/location.png'

import image4 from '../../assets/images/vol-banner.jpg'

import { BsFillArrowRightCircleFill } from "react-icons/bs"
import CTA from '../../components/cta/CTA'
import Map from '../../constants/map/Map'

const ContactPage = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState ({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: ""
  })

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.subject || !formData.phone || !formData.message) {
      // You can display an error message, prevent the submission, or take any other action here
      setErrorMessage('Please fill in all fields!!');
      return;
  }
    try {
        const response = await axios.post('https://fafaeda.com/submit_form', formData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200 ) {
            const data = response.data;
            console.log(data.message);
        } else {
            console.error('Request failed:', response.status, response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
    setFormData({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: ""
  });
  setErrorMessage("")
};

useEffect(() => {
  AOS.init();
}, [])

  return (
    <div className='contactpage'>
        <Navbar />
        <div className="contactpage-container">
            <div id='contact' className="contact-header">
              <div className="contact-header-image">
              <div className='contact-opacity'></div>
                <img src={image} alt="contact" />
              </div>
              <div className="contact-header-text" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                <h1>Contact</h1>
              </div>
            </div>
            <div className="contact-feedback">
              <div className="contact-feedback-title" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
              <div className="home-causes-title">
                    <div className="about-vertical"></div>
                    <h1 style={{color: '#75C2F6', fontSize: '18px', fontWeight:'900'}} >Get in touch</h1>
                </div>
                <p>We Welcome Your Feedback and Stand Ready to Assist You </p>
              </div>
              <div className="contact-feedback-content">
                <div className="contact-feedback-content-left" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                  <div className="contact-feedback-content-image">
                    <img src={image4} alt="banner" />
                  </div>
                  <p>Get in Touch: Your Feedback Guides Us, Your Questions Drive Us, and Your Support Inspires Us. Don't Hesitate to Reach Out — Together, We Can Achieve Remarkable Things!</p>
                  <div className="contact-details">
                  <div className="footer-contact-container">
            <div className="footer-contact-content-container">
              <div className="footer-contact-content-image" style={{marginRight: '0.5rem'}}>
                <img src={image1} alt="icons" />
              </div>
              <div className="footer-contact-content">
                <p>Call Anytime</p>
                <h1 style={{color: 'rgb(32, 32, 32)'}}>08030800469 07082613288</h1>
              </div>
            </div>
            <div className="footer-contact-content-container">
              <div className="footer-contact-content-image" style={{marginRight: '0.5rem'}}>
              <img src={image2} alt="icons" />
              </div>
              <div className="footer-contact-content">
                <p>Send email</p>
                <h1 style={{color: 'rgb(32, 32, 32)'}}>ffafaeda@gmail.com</h1>
              </div>
            </div>
            <div className="footer-contact-content-container">
              <div className="footer-contact-content-image" style={{marginRight: '0.5rem'}}>
              <img src={image3} alt="icons" />
              </div>
              <div className="footer-contact-content">
                <p>Visit us</p>
                <h1 style={{color: 'rgb(32, 32, 32)'}}>51 Rd, Festac Town, Lagos</h1>
              </div>
            </div>
                  </div>
                  </div>
                </div>
                <div className="contact-feedback-content-right" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                  <div className="contact-form">
                    <div className="form-name">
                      <input type='text' placeholder='Your name' name="name" value={formData.name}  onChange={handleChange} />
                    </div>
                    <div className="form-e-s-c">
                    <div className="form-email">
                      <input type='email' placeholder='Email address' name="email" value={formData.email}  onChange={handleChange} />
                    </div>
                    <div className="form-email">
                      <input type='text' placeholder='Subject' name="subject" value={formData.subject}  onChange={handleChange} />
                    </div>
                    </div>
                    <div className="form-name">
                      <input type='text' placeholder='Phone number' name="phone" value={formData.phone} onChange={handleChange}  />
                    </div>
                    <div className="form-name form-textarea">
                      <textarea type='text' placeholder='Message' name="message"  value={formData.message} onChange={handleChange} />
                    </div>
                    <div className="form-button" onClick={handleSubmit} style={{cursor:'pointer', backgroundColor:'red'}}>
                    <BsFillArrowRightCircleFill className='home-about-icon2'/>
                      <p>Send a Message</p>
                    </div>
                    {
                      errorMessage && <div className="error-message" style={{color: 'red', paddingTop:'15px'}}>{errorMessage}</div>

                    }
                  </div>
                </div>
              </div>
            </div>
            <Map />
            <CTA/>
        </div>
        <Footer />
    </div>
  )
}

export default ContactPage