import React, { useEffect } from 'react'
import './homegallery.css'
import { BsFillArrowRightCircleFill } from "react-icons/bs"
import p1 from "../../assets/images/prison/p1.jpeg"
import p5 from "../../assets/images/prison/p4.jpeg"
import c1 from "../../assets/images/children/c1.jpeg"
import sn3 from "../../assets/images/specialNeeds/sn3.jpeg"
import w1 from "../../assets/images/widows/w1.jpeg"


import AOS from 'aos';
import 'aos/dist/aos.css';

const HomeGallery = () => {

    const navigateToGallery = () => {
        // Use the URL of the page you want to navigate to
        const newUrl = '/project';
        
        if (window.location.pathname === newUrl) {
          // If the user is already on the target page, perform a full page reload
          window.location.reload();
        } else {
          // Navigate to the new page
          window.location.href = newUrl;
        }
      };

    useEffect(()=>{
        AOS.init();
    },[])
  return (
    <div className="homegallery">
        <div className="homegallery-container">
            <div className="homegallery-title" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                <h3>Past Events</h3>
                <h1>Relive the Moments: Where Memories Meet Impact</h1>
            </div>
            <div className="homegallery-image-container" data-aos="fade-up"  data-aos-easing="linear" data-aos-duration="1000">
                <div className="homegallery-images gallery-image1">
                    <img src={p1} alt=" gallery " className='gallery-image' />
                    <div className="overlay">
                    <div className="text">
                            <div className="home-gallery-button" onClick={navigateToGallery} >
                                <BsFillArrowRightCircleFill className='home-about-icon2'/>
                                <p>Go to gallery</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="homegallery-images gallery-image2">
                    <img src={p5} alt=" gallery " className='gallery-image' />
                    <div className="overlay">
                    <div className="text">
                       
                    <div className="home-gallery-button" onClick={navigateToGallery} >
                                <BsFillArrowRightCircleFill className='home-about-icon2'/>
                                <p>Go to gallery</p>
                            </div>
                       
                   </div>
                    </div>
                </div>
                <div className="homegallery-images gallery-image3">
                    <img src={c1} alt=" gallery " className='gallery-image' />
                    <div className="overlay">
                    <div className="text">    
                    <div className="home-gallery-button" onClick={navigateToGallery} >
                                <BsFillArrowRightCircleFill className='home-about-icon2'/>
                                <p>Go to gallery</p>
                            </div>
                       
                   </div>
                    </div>
                </div>
                <div className="homegallery-images gallery-image4">
                    <img src={sn3} alt=" gallery " className='gallery-image' />
                    <div className="overlay">
                    <div className="text">
                    <div className="home-gallery-button" onClick={navigateToGallery} >
                                <BsFillArrowRightCircleFill className='home-about-icon2'/>
                                <p>Go to gallery</p>
                            </div>
                       
                   </div>
                    </div>
                </div>
                <div className="homegallery-images gallery-image5">
                    <img src={w1} alt=" gallery " className='gallery-image' />
                    <div className="overlay">
                    <div className="text">
                    <div className="home-gallery-button" onClick={navigateToGallery} >
                                <BsFillArrowRightCircleFill className='home-about-icon2'/>
                                <p>Go to gallery</p>
                            </div>
                       
                   </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>
  )
}

export default HomeGallery