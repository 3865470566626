import React, {useState} from 'react'
import { BsFillArrowRightCircleFill } from "react-icons/bs"
import { Link } from 'react-router-dom';
import {AiOutlineTwitter, AiFillFacebook, AiOutlineInstagram } from "react-icons/ai"
import logo from '../../assets/images/logo.png'
import image1 from '../../assets/images/chat.png'
import image2 from '../../assets/images/mail.png'
import image3 from '../../assets/images/location.png'
import axios from 'axios';
import './footer.css'
import DonateButton from '../../constants/btn/DonateButton';
import bank from '../../assets/images/bank.png'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const Footer = () => {
  const [hasError, setHasError] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const date = new Date();

  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    
    setEmail(e.target.value)
    setHasError(false)
  } 

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      // You can display an error message, prevent the submission, or take any other action here
      setHasError(true)
      return;
  }
    try {
        const response = await axios.post('https://fafaeda.com/newsletter', {email}, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200) {
            const data = response.data;
            console.log(data.message);
        } else {
            console.error('Request failed:', response.status, response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }

    setEmail("")
};

const navigateToHome = () => {
  // Use the URL of the page you want to navigate to
  const newUrl = '/';
  
  if (window.location.pathname === newUrl) {
    // If the user is already on the target page, perform a full page reload
    window.location.reload();
  } else {
    // Navigate to the new page
    window.location.href = newUrl;
  }
};
const navigateToAbout = () => {
  // Use the URL of the page you want to navigate to
  const newUrl = '/about';
  
  if (window.location.pathname === newUrl) {
    // If the user is already on the target page, perform a full page reload
    window.location.reload();
  } else {
    // Navigate to the new page
    window.location.href = newUrl;
  }
};
const navigateToGallery = () => {
  // Use the URL of the page you want to navigate to
  const newUrl = '/project';
  
  if (window.location.pathname === newUrl) {
    // If the user is already on the target page, perform a full page reload
    window.location.reload();
  } else {
    // Navigate to the new page
    window.location.href = newUrl;
  }
};
const navigateToContact = () => {
  // Use the URL of the page you want to navigate to
  const newUrl = '/contact';
  
  if (window.location.pathname === newUrl) {
    // If the user is already on the target page, perform a full page reload
    window.location.reload();
  } else {
    // Navigate to the new page
    window.location.href = newUrl;
  }
};
  return (
    <div className='footer'>
      <div className="footer-container">
        <div className="footer-content1">
        <div className="footer1">
          <div className="footer-header">
            <h1>About</h1>
          </div>
          <p>Empowering Dreams: Transforming Lives with Compassion and Care.</p>
          <div className="footer-button">
            <DonateButton onClick={handleShow} />
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='donation-title'>Donations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="donation-acct">
            <img src={bank} alt="transfer" />
              <p style={{textAlign: 'center'}}>Account Name: First Aid Foundation Against Emotion</p>
              <p style={{textAlign: 'center'}}>Sterling Bank: 0083528298</p>
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button  variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
        </div>
        <div className="footer1">
        <div className="footer-header">
            <h1>Explore</h1>
          </div>
          <ul>
            <li>
                <span onClick={navigateToHome}>Home</span>
              </li>
              <li>
                <span onClick={navigateToAbout}>About</span>
              </li>
              <li>
                <span onClick={navigateToGallery}>Gallery</span>
              </li>
              <li>
                <span onClick={navigateToContact}>Contact</span>
              </li>
          </ul>
        </div>
        <div className="footer1">
        <div className="footer-header">
            <h1>Contact</h1>
          </div>
          <div className="footer-contact-container">
            <div className="footer-contact-content-container">
              <div className="footer-contact-content-image">
                <img src={image1} alt="icons" />
              </div>
              <div className="footer-contact-content">
                <p>Call Anytime</p>
                <h1>08030800469 07082613288</h1>
              </div>
            </div>
            <div className="footer-contact-content-container">
              <div className="footer-contact-content-image">
              <img src={image2} alt="icons" />
              </div>
              <div className="footer-contact-content">
                <p>Send email</p>
                <h1>ffafaeda@gmail.com</h1>
              </div>
            </div>
            <div className="footer-contact-content-container">
              <div className="footer-contact-content-image">
              <img src={image3} alt="icons" />
              </div>
              <div className="footer-contact-content">
                <p>Visit us</p>
                <h1>51 Rd, Festac Town, Lagos</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="footer1">
        <div className="footer-header">
            <h1>Newsletter</h1>
          </div>
          <p>Dive into a World of Compassion, Where Everyone Deserves a Smile.</p>
          <div className={`footer-newsletter ${hasError ? 'error1' : ''}`}>
            <input type='email' placeholder='Email Address' name="email" value={email} onChange={handleChange}/> 
            <div className="footer-button" onClick={handleSubmit}>
              <BsFillArrowRightCircleFill style={{color: 'white'}} />
              <p>Send</p>
        </div>
        </div>
        </div>
        </div>
        <div className="footer-content2">
          <div className="footer-comapany-icon">
            <img src={logo} alt="company-logo" />
          </div>
          <div className="footer-company-bottom-content">
          <div className="footer-social-icons">
            <div className="footer-social-div">
              <Link to='/'><AiOutlineTwitter  className='footer-social-logo'/></Link>
            </div>
            <div className="footer-social-div">
              <Link to='/'><AiFillFacebook  className='footer-social-logo'/></Link>
            </div>
            <div className="footer-social-div">
              <Link to='/'><AiOutlineInstagram  className='footer-social-logo'/></Link>
            </div>
          </div>
          <div className="footer-copyright">
            <p> &#169; Copyright {date.getFullYear()}, FAFAEDA </p>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Footer